<template>
  <v-row>
    <v-col cols="12">
      <v-card class="px-0">
        <v-skeleton-loader v-if="$store.state.customers.retrieveLoader" transition="fade-transition" type="toolbar" />
        <v-toolbar v-else color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Personas</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-query-builder :query-map="filters" model="customer" class="mr-1" />
              <v-btn @click="modalContact=true" outlined color="grey-500"><v-icon left small>mdi-plus</v-icon>Nuevo</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-0">
          <v-skeleton-loader v-if="$store.state.customers.retrieveLoader" transition="fade-transition" type="table" />
          <template v-else>
            <template v-if="contactsList.length">
              <v-data-table :headers="headers" :items="contactsList" :items-per-page="pagination" mobile-breakpoint hide-default-footer hide-default-header disable-sort @click:row="(contactsList) => {$router.push({name: 'ContactRetrieveUpdate', params: {id: contactsList.id}})}" style="cursor: pointer">
                <!-- header -->
                <template v-slot:[`header`]="{ props: { headers } }">
                  <VTableHeaders :headers="headers" @sort="setSort" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.name`]="{item}">
                  <span class="grey-500--text font-weight-medium">{{item.name}}</span>
                </template>
                <template v-slot:[`item.email`]="{item}">
                  <span>{{item.email}}</span>
                </template>
                <template v-slot:[`item.phone`]="{item}">
                  <span v-if="item.phone">{{item.phone}}</span>
                  <span class="font-italic text--disabled" v-else>Sin teléfono</span>
                </template>
                <template v-slot:[`item.documentation`]="{item}">
                  <template v-if="item.documentation || item.collections">
                    <v-chip v-if="item.documentation" class="mr-1" color="defaultGrey" text-color="grey-500" small>Emisión</v-chip>
                    <v-chip v-if="item.collections" color="defaultGrey" text-color="grey-500" small>Cobranza</v-chip>
                  </template>
                  <span class="font-italic text--disabled" v-else>Sin notificaciones activas</span>
                </template>
                <template v-slot:[`item.created`]="{item}">
                  <span class="text-right">{{item.created | date}}</span>
                </template>
              </v-data-table>
              <template v-if="showPagination || showAll">
                <v-divider />
                <v-row class="mx-0" align="center">
                  <template v-if="showPagination">
                    <v-col cols="6">
                      <span class="body-2 grey-500--text">{{count}} registros</span>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                    </v-col>
                  </template>
                </v-row>
              </template>
            </template>
            <v-row v-else>
              <v-col class="pb-6 pt-4 text-center">
                <v-icon class="d-block" color="grey-500" size="70">mdi-account-multiple</v-icon>
                <span class="d-block subtitle-1 my-2">Sin personas registradas</span>
                <v-btn @click="modalContact=true" outlined>Crear una persona</v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- dialog create contact -->
    <v-dialog v-model="modalContact" width="700" scrollable persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="55">
            <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Crear persona</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-1">
                <v-btn class="ml-3" @click="modalContact=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-text-field v-model="$v.newContact.name.$model" :error="$v.newContact.name.$error" outlined required single-line dense placeholder="Escribe un nombre" maxlength="30" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="10">
                  <v-text-field v-model="$v.newContact.email.$model" :error="$v.newContact.email.$error" outlined required single-line dense placeholder="Escribe un email" maxlength="60" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Teléfono</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="6">
                  <v-text-field v-model="$v.newContact.phone.$model" :error="$v.newContact.phone.$error" outlined required single-line dense placeholder="Escribe un teléfono" maxlength="16" v-mask="`${newContact.phone} # #### ####`" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="grey-500--text font-weight-medium">¿Qué notificaciones debe recibir esta persona?</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Emisión</v-list-item-title>
              <v-list-item-subtitle>
                <v-switch class="mt-n4" v-model="newContact.documentation" color="green-500" style="position: absolute" />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Cobranza</v-list-item-title>
              <v-list-item-subtitle>
                <v-switch class="mt-n4" v-model="newContact.collections" color="green-500" style="position: absolute" />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="modalContact=false, $v.newContact.$reset()" outlined>Cancelar</v-btn>
          <v-btn @click="createContact" :loading="$store.state.loading" color="blue-500">Crear persona</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create contact -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import VTableHeaders from '@/components/VTable/VTableHeaders'
export default {
  components: {
    VQueryBuilder,
    VTableHeaders
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    newContact: {
      name: '',
      email: '',
      phone: '',
      documentation: false,
      collections: false
    },
    filters: [
      {
        type: 'text',
        id: 'email',
        label: 'Email',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'customer__name',
        label: 'Cliente',
        operators: ['contiene']
      },
      {
        type: 'radio',
        id: 'documentation',
        label: 'Recibe emisión',
        choices: [
          {label: 'Si', value: 'True'},
          {label: 'No', value: 'False'}
        ]
      },
      {
        type: 'radio',
        id: 'collections',
        label: 'Recibe cobranza',
        choices: [
          {label: 'Si', value: 'True'},
          {label: 'No', value: 'False'}
        ]
      }
    ],
    headers: [
      { text: 'Nombre', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'Email', value: 'email', sortable: true },
      { text: 'Teléfono', value: 'phone' },
      { text: 'Notificaciones activas', value: 'documentation' },
      { text: 'Creado el', value: 'created', align: 'end', sortable: true }
    ],
    modalContact: false
  }),
  computed: mapState({
    count: state => state.customers.contactsCount,
    contactsList: state => state.customers.contactsList
  }),
  methods: {
    getList () {
      this.$store.dispatch('customers/LIST', {
        resource: 'contacts',
        query: this.$route.query
      })
    },
    createContact () {
      this.$v.newContact.$touch()
      if (this.$v.newContact.$invalid) {
        return false
      }
      this.$store.dispatch('customers/CREATE', {
        resource: 'contacts',
        payload: this.newContact
      })
      .then((response) => {
        this.$router.push({
          name: 'ContactRetrieveUpdate',
          params: {
            id: response.data.id
          }
        })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    }
  },
  validations: {
    newContact: {
      name: {
        required,
        maxLength: maxLength(30)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      phone: {
        maxLength: maxLength(12)
      }
    }
  }
}
</script>